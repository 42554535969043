<template>
  <v-row align-content="center" class="white">
    <v-col class="py-0 mx-auto white" md="8">
      <v-row v-if="error">
        <v-col style="height: 100vh">
          <v-spacer class="py-8"></v-spacer>
          <transition name="fade">
            <div class="px-8">
              <v-alert type="error">Link is invalid</v-alert>
            </div>
          </transition>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="white px-12 mx-auto" md="11" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <transition name="fade">
            <v-form
              v-if="show"
              class="px-8"
              @submit.prevent="submit"
              ref="form"
              lazy-validation
              v-model="valid"
            >
              <div class="fs-32 font-weight-bold mb-4">New password</div>
              <div class="fs-18 color-disable mb-3">
                Password harus mengandung sedikitnya 7 huruf dan 1 angka
              </div>
              <v-row class="pb-0">
                <v-col>
                  <div class="mb-2">
                    <span class="font-weight-medium">Password</span>
                  </div>
                  <v-text-field
                    v-model="password"
                    type="password"
                    placeholder="Masukkan password baru anda"
                    :rules="pswRules"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-2">
                    <span class="font-weight-medium">Konfirmasi Password</span>
                  </div>
                  <v-text-field
                    class=""
                    v-model="confirm_password"
                    placeholder="Masukkan ulang password anda"
                    :rules="cnfrmPswRules"
                    type="password"
                    outlined
                    hide-details="auto"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    large
                    elevation="0"
                    type="submit"
                    color="primary"
                    :loading="submiting"
                    block
                  >
                    Reset password
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <div v-else class="px-8">
              <div class="fs-32 font-weight-bold mb-4">Hello</div>
              <div class="fs-18 color-disable mb-2">
                Permohonan memperbarui password telah dibuat. Jika anda yang
                membuat permohonan ini, harap klik tombol di bawah ini.
              </div>
              <v-spacer class="pb-2"></v-spacer>
              <v-row>
                <v-col>
                  <v-btn
                    large
                    elevation="0"
                    color="primary"
                    :loading="submiting"
                    block
                    @click="show = true"
                  >
                    Reset password
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </transition>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "password-reset",
  data() {
    return {
      error: false,
      errors: [],
      valid: true,
      password: "",
      confirm_password: "",
      submiting: false,
      show: false,
      pswRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
      ],
    };
  },
  computed: {
    cnfrmPswRules() {
      return [
        () =>
          this.password === this.confirm_password ||
          "confirmation password must match",
        (v) => !!v || "Confirmation Password is required",
      ];
    },
  },
  created() {
    this.error = false;
    console.log("wPoMbnqw40jC6kQoXjvKqyc1X9Y_RI27t_dq9FIu2khB_WamlXAGY_WDI5OO0V7_bnqV9xTUxrrba6WfaZligg==" == this.$route.params.code);
    this.axios
      .post(
        "/users/v1/member/verify-forgot-password/",
        {
          access_key: process.env.VUE_APP_ACCESS_KEY,
          code: this.$route.params.code,
        },
        {
          headers: {
            "Content-type": "application/json",
            // "Authorization": null
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        this.error = true;
        console.error(error);
      });
  },
  methods: {
    submit() {
      this.submiting = true;
      this.axios
        .put("/users/v1/member/change-forgot-password/", {
          access_key: process.env.VUE_APP_ACCESS_KEY,
          code: this.$route.params.code,
          new_password: this.password,
          conf_new_password: this.confirm_password,
        },
        {
          headers: {
            "Content-type": "application/json",
            // "Authorization": null
          },
        })
        .then(() => {
          this.$router.push("/success-reset-password");
          this.submiting = false;
        })
        .catch((error) => {
          this.submiting = false;
          console.error(error);
        });
    },
    hasError(input) {
      return this._.has(this.errors, input);
    },
  },
};
</script>

<style>
</style>